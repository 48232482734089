const generalConstants = {
  imageSize: 500,
  RECORD_LIMIT: 10,
  RECORD_SKIP: 0,
  NOT_AVAILABLE: 'N/A',
  PASS: 'pass',
  FAIL: 'fail',
  NO_RECORD_fOUND: 'No Record found',
  DAY_RANGE: ['monday', 'sunday'],
  TIME_RANGE: ['0:00 am', '0:00 am'],
  WEEK_START_DAY: 'Monday',
  MONTH: 'Month',
  WEEK: 'Week',
  DAY: 'Day',
  MYQAMPUS_VIDEO: 'https://www.youtube.com/embed/y2_80BoV-rY?rel=0&loop=1',
}
export const ROLES_LIST = [
  { id: '0', name: 'Student' },
  { id: '1', name: 'All Student' },
  { id: '2', name: 'Teacher' },
  { id: '3', name: 'All Teacher' },
  { id: '4', name: 'Gaurdian' },
  { id: '5', name: 'All Gaurdian' },
  { id: '6', name: 'Staff' },
  { id: '7', name: 'All Staff' },
]

export const MEDIUM_LIST = [
  { id: '1', name: 'Email' },
  { id: '2', name: 'Sms' },
  { id: '3', name: 'Web Push Notifications' },
]
export const CARD_LIST = [
  {
    parentRoute: 'features',
    modal: 'feature-detail-modal',
    routeName: 'fee-manager',
    symbol: 'fee-manager',
    title: 'FHE_C1_T',
    description: 'FHE_C1_D',
    color: 'primary-purple-600',
    shadow: 'shadow-primary-purple-600',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'attendance-manager',
    symbol: 'attendance-manager',
    title: 'FHE_C2_T',
    description: 'FHE_C2_D',
    color: 'secondary-orange-500',
    shadow: 'shadow-secondary-orange-500 ',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-modal',
    routeName: 'timetable-manager',
    symbol: 'time-manager',
    title: 'FHE_C3_T',
    description: 'FHE_C3_D',
    color: 'success-500',
    shadow: 'shadow-primary-light-green ',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'syllabus-manager',
    symbol: 'syllabus',
    title: 'FHE_C4_T',
    description: 'FHE_C4_D',
    color: 'error-600',
    shadow: 'shadow-error-600',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-modal',
    routeName: 'notification-and-reminder',
    symbol: 'notification',
    title: 'FHE_C5_T',
    description: 'FHE_C5_D',
    color: 'warning-500',
    shadow: 'shadow-warning-500',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'roles-and-permissions',
    symbol: 'setting',
    title: 'FHE_C6_T',
    description: 'FHE_C6_D',
    color: 'secondary-blue-600',
    shadow: 'shadow-secondary-blue-600',
    card: false,
    // this
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-modal',
    routeName: 'white-labelling',
    symbol: 'white-label',
    title: 'FHE_C7_T',
    description: 'FHE_C7_D',
    color: 'secondary-blue-gray-500',
    shadow: 'shadow-secondary-blue-gray-500',
    card: false,
    // this
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'pickup-facility',
    symbol: 'user',
    title: 'FHE_C8_T',
    description: 'FHE_C8_D',
    color: 'secondary-rose-500',
    shadow: 'shadow-secondary-rose-500',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'announcements',
    symbol: 'notification',
    title: 'ANNOUNCEMENTS',
    description: 'ANNOUNCEMENTS_DESCRIPTION',
    color: 'primary-purple-600',
    shadow: 'shadow-primary-purple-600',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'exams',
    symbol: 'attendance-manager',
    title: 'EXAMS',
    description: 'EXAMS_DESCRIPTION',
    color: 'secondary-orange-500',
    shadow: 'shadow-secondary-orange-500 ',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'assignments',
    symbol: 'syllabus',
    title: 'ASSIGNMENTS',
    description: 'ASSIGNMENTS_DESCRIPTION',
    color: 'success-500',
    shadow: 'shadow-primary-light-green ',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'parent-teacher-meetings',
    symbol: 'time-manager',
    title: 'APPOINTMENT',
    description: 'APPOINTMENT_DESCRIPTION',
    color: 'error-600',
    shadow: 'shadow-error-600',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'parents-query-management',
    symbol: 'user',
    title: 'SUPPORT',
    description: 'SUPPORT_DESCRIPTION',
    color: 'warning-500',
    shadow: 'shadow-warning-500',
    card: true,
  },
  {
    parentRoute: 'features',
    modal: 'feature-detail-carousel-modal',
    routeName: 'grading-system',
    symbol: 'fee-manager',
    title: 'GRADING_SYSTEM',
    description: 'GRADING_SYSTEM_DESCRIPTION',
    color: 'secondary-blue-gray-500',
    shadow: 'shadow-secondary-blue-gray-500',
    card: true,
  },
]

/**
 * User Constants
 */
export const USER_CONSTANTS = {
  GENDERS: [
    { title: 'MALE', value: 'male' },
    { title: 'FEMALE', value: 'female' },
    { title: 'OTHERS', value: 'others' },
    { title: 'NOT_SELECTED', value: '' },
  ],
  LOGIN_ENABLED_STATUSES: [
    { title: 'YES', value: true },
    { title: 'NO', value: false },
  ],
}

export const navbarLinks = [
  { to: '/features', activeNavBarLink: 'Features', name: 'Features' },
  { to: '/pricing', activeNavBarLink: 'pricing', name: 'Pricing' },
  { to: '/about-us', activeNavBarLink: 'About_Us', name: 'About Us' },
]

export const NOTIFICATION_STATUS = {
  READ: 'Read',
  UNREAD: 'Unread',
  READ_ALL_SMALL: 'read',
  UNREAD_ALL_SMALL: 'unread',
}

export const COMMUNICATION_MEDIUMS = {
  WEBPUSH: 'webpush',
  SMS: 'sms',
  EMAIL: 'email',
}

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const DEFAULT_LANGUAGE = {
  id: 0,
  short: 'en',
  long: 'English',
  dir: 'ltr',
}

export default generalConstants
